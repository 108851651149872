import React, { useEffect, useState } from "react";

const Ending = (props) => {
	const [val, setVal] = useState(false);
	const [color, setColor] = useState("");

	function generateLightColorHex() {
		if (color !== "") {
			return color;
		} else {
			let color = "#";
			for (let i = 0; i < 3; i++)
				color += ("0" + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)).slice(
					-2
				);
			return color;
		}
	}

	let randomColor = generateLightColorHex();

	useEffect(() => {
		setTimeout(() => {
			setColor(randomColor);
			setVal(true);
		}, 4000);
	}, [val, randomColor]);

	let choice = props.selectors.join(", ");
	// let randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);

	return val ? (
		<main id="ending">
			<div id="title">{props.name}'s i-Jeonghan Color</div>
			<div id="color" style={{ backgroundColor: color }}></div>
			<div id="recipe">
				<div className="recipe-title">Ingredient Analysis Table</div>
				<div className="recipe-item">LOVE 1004%</div>
				<div className="recipe-item">MY CHOICE ({choice}) 1004%</div>
				<div className="recipe-item">HAPPY 1004%</div>
				<div className="recipe-item">1995-10-04</div>
			</div>
		</main>
	) : (
		<main id="ending-load">
			<div id="loading">Your i-Jeonghan color is</div>
		</main>
	);
};

export default Ending;
