import React, { useState, useEffect } from "react";
import "./App.scss";

// Subscreens
import Intro from "./Screens/Intro";
import Name from "./Screens/Name";
import Selection from "./Screens/Selection";
import Happy from "./Screens/Happy";
import Ending from "./Screens/Generated";

function App() {
	/* Set window height */
	const [height, setHeight] = useState({ height: window.innerHeight });

	let resize = () => {
		setHeight({ height: window.innerHeight });
	};

	useEffect(() => {
		window.addEventListener("resize", resize);

		return () => {
			window.removeEventListener("resize", resize);
		};
	});

	// Set Name
	const [name, setName] = useState("");

	// Selectors Management
	const [selectors, setSelectors] = useState([]);
	const SELECTORLIST = [
		"똑똑한",
		"침착한",
		"예쁜",
		"사랑스러운",
		"멋진",
		"무대위의",
		"장난스러운",
		"웃고있는",
		"운이좋은",
		"노력하는",
		"윙크하는",
		"어른스러운",
		"다정한",
		"앙냥냥대는",
		"잘생긴",
		"일상속의",
	];

	// Stage management
	const [currentStep, setCurrentStep] = useState(0);

	let proceedStep = () => {
		setCurrentStep(currentStep + 1);
	};

	let returnStep = () => {
		setCurrentStep(currentStep - 1);
	};

	let currentElement;

	switch (currentStep) {
		case 0:
			currentElement = <Intro proceed={() => proceedStep()}></Intro>;
			break;
		case 1:
			currentElement = <Name setName={setName} proceed={() => proceedStep()}></Name>;
			break;
		case 2:
			currentElement = (
				<Selection
					name={name}
					setSelector={setSelectors}
					selectorSet={SELECTORLIST}
					proceed={() => proceedStep()}></Selection>
			);
			break;
		case 3:
			currentElement = <Happy name={name} proceed={() => proceedStep()} />;
			break;
		default:
			currentElement = <Ending name={name} selectors={selectors} />;
			break;
	}

	return (
		<div id="display" style={height}>
			{currentElement}
		</div>
	);
}

export default App;
