import React, { useState } from "react";
import Button from "../Components/Button/Button";

const Name = (props) => {
	const [name, setName] = useState("");
	const [warningVisible, setWarningVisible] = useState(false);

	let handleInput = (event) => {
		if (name.length < 7 || event.key === "Backspace") {
			setName(event.target.value);
			setWarningVisible(false);
		} else {
			event.target.value = event.target.value.substr(0, 7);
			setName(event.target.value);
			setWarningVisible(true);
		}
	};

	let onProceed = () => {
		if (name === "") {
			props.setName(Math.floor(Math.random() * 10000).toString());
		} else {
			props.setName(name);
		}

		props.proceed();
	};

	return (
		<main id="name">
			<div>
				<div id="title">What is your i-Jeonghan color?</div>
				<div id="subtitle">
					이름을 공란으로 설정할 경우, <br /> 숫자로 이루어진 고유 코드를 부여해드립니다.
				</div>
			</div>
			<div id="name-input-wrapper">
				<label>name:</label>
				<input onKeyUp={handleInput} id="name-input" defaultValue=""></input>
			</div>
			{warningVisible && (
				<div className="warning">
					<div className="warning-text">이름은 최대 7글자까지 입력 가능합니다</div>
				</div>
			)}
			<Button onClick={() => onProceed()}>next</Button>
		</main>
	);
};

export default Name;
