import React, { useState } from "react";
import Button from "../Components/Button/Button";

import Checkbox from "../Components/Checkbox/Checkbox";

const Selection = (props) => {
	const [selected, setSelected] = useState([]);
	const [overWarning, setOverWarning] = useState(false);
	const [underWarning, setUnderWarning] = useState(false);

	let onProceed = () => {
		if (selected.length < 6) {
			setUnderWarning(true);
		} else {
			props.setSelector(selected);
			props.proceed();
		}
	};

	return (
		<main id="selection">
			<div>
				<div id="title">{props.name}'s i-Jeonghan Color Recipe</div>
				<div id="subtitle">(6가지를 선택해주세요)</div>
			</div>
			{overWarning && <div className="warning">최대 6가지까지 선택할 수 있습니다.</div>}
			{underWarning && <div className="warning">6가지 항목을 선택해 주셔야 합니다.</div>}
			<div id="selectors-wrapper">
				{props.selectorSet.map((item, index) => {
					return (
						<Checkbox
							key={index}
							currSelection={selected}
							setSelection={setSelected}
							underWarning={setUnderWarning}
							overWarning={setOverWarning}>
							{item}
						</Checkbox>
					);
				})}
			</div>
			<div id="next-wrapper">
				<Button onClick={() => onProceed()}>next</Button>
				<div className="selector-stage">(1/2)</div>
			</div>
		</main>
	);
};

export default Selection;
