import React, { useState } from "react";
import "./Checkbox.scss";

const Checkbox = (props) => {
	const [checked, setChecked] = useState(false);

	let toggleCheck = () => {
		props.underWarning(false);
		props.overWarning(false);

		let curr = props.currSelection;

		if (checked) {
			setChecked(false);
			curr = curr.filter((val) => {
				return val !== props.children;
			});
			// props.setSelection(curr);
		}

		if (curr.length < 6 && !checked) {
			setChecked(true);
			curr.push(props.children);
		} else if (curr.length >= 6) {
			props.overWarning(true);
		}

		props.setSelection(curr);
	};

	return (
		<div className="checkbox" onClick={() => toggleCheck()}>
			<button className={checked ? "check checked" : "check"}></button>
			<div>{props.children}</div>
		</div>
	);
};

export default Checkbox;
