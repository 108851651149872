import React from "react";
import Button from "../Components/Button/Button";

const Intro = (props) => {
	return (
		<main id="intro">
			<div id="title">
				Let me know your
				<br /> i-Jeonghan Color!
			</div>
			<div id="subtitle">
				당신이 사랑하는 정한이의 모습은 어떤 모습인가요?
				<br />
				어디 하나 빠짐없이 사랑스럽지만,
				<br /> 내가 특히나 더 사랑하는 정한이의 모습은 <br />
				어떤 모습인지 [내가정한]-색 으로 알아봅시다.
				<span className="warning">해당 페이지를 타인에게 공유하는 것을 금지합니다.</span>
			</div>
			<Button onClick={() => props.proceed()}>start</Button>
		</main>
	);
};

export default Intro;
