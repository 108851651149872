import React, { useState } from "react";
import Button from "../Components/Button/Button";

import Checkbox from "../Components/Checkbox/Checkbox";

const Happy = (props) => {
	const [selected, setSelected] = useState([]);
	const [warningVisible, setWarningVisible] = useState(false);
	const [val, setVal] = useState(false);
	let onProceed = () => {
		if (selected.length > 0) {
			props.proceed();
		} else {
			setWarningVisible(true);
		}
	};

	return (
		<main id="selection-happy">
			<div id="title">{props.name}'s Jeonghan color Recipe</div>

			<div id="selectors-wrapper">
				<Checkbox
					underWarning={setVal}
					overWarning={setVal}
					currSelection={selected}
					setSelection={setSelected}>
					행복한
				</Checkbox>
			</div>
			{warningVisible && selected.length === 0 && (
				<div className="warning">정한이는 행복해야 합니다!</div>
			)}

			<div id="next-wrapper">
				<Button onClick={() => onProceed()}>done</Button>
				<div className="selector-stage">(2/2)</div>
			</div>
		</main>
	);
};

export default Happy;
