import React from "react";
import "./Button.scss";

const Button = (props) => {
	return (
		<button className="navbutton" onClick={props.onClick}>
			{props.children}
		</button>
	);
};

export default Button;
